$(function () {
	APP.init();
	lozadInit();
	fancyboxInit();
	countUpInit();

	accordionCollapse();
	loadMoreItem();
	$('.career-detail-modal-title').text($('.career-detail-section h1').text())
});

$(window).on('scroll', function () {
	APP.fixed();
});

// variable
var header = $("header"),
	body = $("body"),
	backToTop = $(".back-to-top"),
	buttonMenu = $("#buttonMenu"),
	mobileWrap = $(".mobile-wrap"),
	buttonSearch = $("header .button-search"),
	searchWrap = $(".search-wrap"),
	heightHeader = $("header").height(),
	heightWindow = $(window).height(),
	widthWindow = $(window).width(),
	outerHeightWindow = $(window).outerHeight();

// toggleText
$.fn.extend({
	toggleText: function (a, b) {
		return this.text(this.text() == b ? a : b);
	},
});

var APP = {
	fixed: () => {
		// header
		$(window).scrollTop() > heightHeader
			? header.addClass("active")
			: header.removeClass("active");
		$(window).scrollTop() > (outerHeightWindow - heightHeader)
			? backToTop.addClass("active")
			: backToTop.removeClass("active");
	},
	backToTop: () => {
		backToTop.on('click', function () {
			$('html, body').animate({ scrollTop: 0 }, 500);
		})
	},
	mapping: () => {
		let menuLeft = $("header .header-left .main-menu").mapping({
			mobileWrapper: ".mobile-wrap .navbar-nav-list",
			mobileMethod: "prependTo",
			desktopWrapper: "header .header-left",
			desktopMethod: "appendTo",
			breakpoint: 1199.98,
		});
		let mainRight = $("header .header-right .main-menu").mapping({
			mobileWrapper: ".mobile-wrap .navbar-nav-list",
			mobileMethod: "appendTo",
			desktopWrapper: "header .header-right",
			desktopMethod: "prependTo",
			breakpoint: 1199.98,
		});
	},
	toggleMenuMobile: () => {
		$(buttonMenu).on('click', function () {
			mobileWrap.slideDown().toggleClass('active');
			$('.backdrop-mobile').fadeIn();
		});

		$(mobileWrap).find('.close-mobile').on('click', function () {
			mobileWrap.fadeOut().removeClass('active');
			$('.backdrop-mobile').fadeOut();
		});

		$(document).on('click', function (e) {
			if (!$(e.target).closest(mobileWrap).length && !$(e.target).closest(buttonMenu).length) {
				mobileWrap.fadeOut().removeClass('active');
				$('.backdrop-mobile').fadeOut();
			}
		});
		$('.main-menu .menu-item-has-children > .sub-menu').each(function () {
			var toggleSubmenu = $('<span class="toggle-submenu"></span>');
			$(this).before(toggleSubmenu);
		});
		$('.main-menu .menu-item-has-children > .mega-menu').each(function () {
			var toggleMegaMenu = $('<span class="toggle-mega"></span>');
			$(this).before(toggleMegaMenu);
		});
		$('.main-menu .menu-item-has-children > .mega-wrap').each(function () {
			var toggleMegaWrap = $('<span class="toggle-wrap"></span>');
			$(this).before(toggleMegaWrap);
		});
		$('.main-menu .toggle-submenu, .main-menu .toggle-mega, .main-menu .toggle-wrap').on('click', function () {
			if (widthWindow < 1200) {
				$(this).toggleClass('active');
				$(this).next().slideToggle();
			}
		});
	},
	toggleSearch: () => {
		buttonSearch.on('click', function () {
			searchWrap.fadeToggle();
			searchWrap.find('input').trigger('focus');
			$('.backdrop-search').fadeToggle('fast');
		});

		$(document).on('click', function (e) {
			if (!$(e.target).closest(searchWrap).length && !$(e.target).closest(buttonSearch).length) {
				searchWrap.fadeOut('fast')
				$('.backdrop-search').fadeOut('fast');
			}
		});
	},
	init: () => {
		APP.backToTop();
		APP.mapping();
		APP.toggleMenuMobile();
		APP.toggleSearch();
	}
}

var defaultSettingSwiper = {
	preventInteractionOnTransition: true,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
}

const homeBannerSwiper = new Swiper(".banner-slider .swiper", {
	loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
		pauseOnMouseEnter: false,
	},
	// effect: "fade",
	speed: 1000,
	...defaultSettingSwiper,
	navigation: {
		nextEl: ".banner-slider .button-next",
		prevEl: ".banner-slider .button-prev",
	},
	pagination: {
		el: ".banner-slider .swiper-pagination",
		clickable: true,
	},
});

$('.one-slider').each(function (index) {
	var loopDefault = {
		loop: true,
	};
	var autoplayDefault = {
		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
	};
	var centeredSlidesDefault = {};
	var effectDefault = {};
	var verticalDefault = {};
	if ($(this).hasClass('no-loop')) {
		loopDefault = {
			loop: false,
		};
	}
	if ($(this).hasClass('no-autoplay')) {
		autoplayDefault = {
			autoplay: false,
		};
	}
	if ($(this).hasClass('centered-slides')) {
		centeredSlidesDefault = {
			centeredSlides: true,
			centeredSlidesBounds: true,
		};
	}
	if ($(this).hasClass('effect-fade')) {
		effectDefault = {
			effect: 'fade',
		};
	}
	if ($(this).hasClass('is-vertical')) {
		verticalDefault = {
			direction: 'vertical',
		};
	}
	$(this).find('.swiper').addClass(`swiper-one-${index}`);
	$(this).find('.swiper-pagination').addClass(`pagination-one-${index}`);
	$(this).find('.button-prev').addClass(`prev-one-${index}`);
	$(this).find('.button-next').addClass(`next-one-${index}`);
	const oneSlider = new Swiper(`.swiper-one-${index}`, {
		...defaultSettingSwiper,
		...loopDefault,
		...autoplayDefault,
		...centeredSlidesDefault,
		// ...effectDefault,
		...verticalDefault,
		speed: 1000,
		spaceBetween: 20,
		slidesPerView: 1,
		slideToClickedSlide: true,
		loopAdditionalSlides: 1,
		navigation: {
			prevEl: `.one-slider .prev-one-${index}`,
			nextEl: `.one-slider .next-one-${index}`,
		},
		pagination: {
			el: `.one-slider .pagination-one-${index}`,
			clickable: true,
			dynamicBullets: true,
		},
		breakpoints: {
			1200: {
				spaceBetween: 32,
			},
			1400: {
				spaceBetween: 40,
			},
		},
	});
});

$('.two-slider').each(function (index) {
	var loopDefault = {
		loop: true,
	};
	var autoplayDefault = {
		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
	};
	var centeredSlidesDefault = {};
	if ($(this).hasClass('no-loop')) {
		loopDefault = {
			loop: false,
		};
	}
	if ($(this).hasClass('no-autoplay')) {
		autoplayDefault = {
			autoplay: false,
		};
	}
	if ($(this).hasClass('centered-slides')) {
		centeredSlidesDefault = {
			centeredSlides: true,
			centeredSlidesBounds: true,
		};
	}
	$(this).find('.swiper').addClass(`swiper-two-${index}`);
	$(this).find('.swiper-pagination').addClass(`pagination-two-${index}`);
	$(this).find('.button-prev').addClass(`prev-two-${index}`);
	$(this).find('.button-next').addClass(`next-two-${index}`);
	const twoSlider = new Swiper(`.swiper-two-${index}`, {
		...defaultSettingSwiper,
		...loopDefault,
		...autoplayDefault,
		speed: 1000,
		spaceBetween: 16,
		slidesPerView: 1,
		navigation: {
			prevEl: `.two-slider .prev-two-${index}`,
			nextEl: `.two-slider .next-two-${index}`,
		},
		pagination: {
			el: `.two-slider .pagination-two-${index}`,
			clickable: true,
			dynamicBullets: true,
		},
		breakpoints: {
			768: {
				slidesPerView: 2,
				spaceBetween: 32,
			},
			1200: {
				slidesPerView: 2,
				spaceBetween: 32,
			},
		},
	});
});

$('.three-slider').each(function (index) {
	var loopDefault = {
		loop: true,
	};
	var autoplayDefault = {
		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
	};
	var centeredSlidesDefault = {};
	if ($(this).hasClass('no-loop')) {
		loopDefault = {
			loop: false,
		};
	}
	if ($(this).hasClass('no-autoplay')) {
		autoplayDefault = {
			autoplay: false,
		};
	}
	if ($(this).hasClass('centered-slides')) {
		centeredSlidesDefault = {
			centeredSlides: true,
			centeredSlidesBounds: true,
		};
	}
	$(this).find('.swiper').addClass(`swiper-three-${index}`);
	$(this).find('.swiper-pagination').addClass(`pagination-three-${index}`);
	$(this).find('.button-prev').addClass(`prev-three-${index}`);
	$(this).find('.button-next').addClass(`next-three-${index}`);
	const threeSlider = new Swiper(`.swiper-three-${index}`, {
		...defaultSettingSwiper,
		...loopDefault,
		...autoplayDefault,
		speed: 1000,
		spaceBetween: 16,
		slidesPerView: 1,
		navigation: {
			prevEl: `.three-slider .prev-three-${index}`,
			nextEl: `.three-slider .next-three-${index}`,
		},
		pagination: {
			el: `.three-slider .pagination-three-${index}`,
			clickable: true,
			dynamicBullets: true,
		},
		breakpoints: {
			576: {
				slidesPerView: 2,
				spaceBetween: 16,
			},
			1024: {
				slidesPerView: 3,
				spaceBetween: 16,
			},
			1200: {
				slidesPerView: 3,
				spaceBetween: 32,
			},
		},
	});
});

$('.four-slider').each(function (index) {
	var loopDefault = {
		loop: true,
	};
	var autoplayDefault = {
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
	};
	var centeredSlidesDefault = {};
	if ($(this).hasClass('no-loop')) {
		loopDefault = {
			loop: false,
			rewind: true,
		};
	}
	if ($(this).hasClass('no-autoplay')) {
		autoplayDefault = {
			autoplay: false,
		};
	}
	if ($(this).hasClass('centered-slides')) {
		centeredSlidesDefault = {
			centeredSlides: true,
			centeredSlidesBounds: true,
		};
	}
	$(this).find('.swiper').addClass(`swiper-four-${index}`);
	$(this).find('.swiper-pagination').addClass(`pagination-four-${index}`);
	$(this).find('.swiper-scrollbar').addClass(`scrollbar-four-${index}`);
	$(this).find('.button-prev').addClass(`prev-four-${index}`);
	$(this).find('.button-next').addClass(`next-four-${index}`);
	const fourSlider = new Swiper(`.swiper-four-${index}`, {
		...defaultSettingSwiper,
		...loopDefault,
		...autoplayDefault,
		speed: 1000,
		spaceBetween: 16,
		slidesPerView: 1,
		navigation: {
			prevEl: `.four-slider .prev-four-${index}`,
			nextEl: `.four-slider .next-four-${index}`,
		},
		pagination: {
			el: `.four-slider .pagination-four-${index}`,
			clickable: true,
			dynamicBullets: true,
		},
		scrollbar: {
			el: `.four-slider .scrollbar-four-${index}`,
			hide: false,
		},
		breakpoints: {
			576: {
				slidesPerView: 2,
				spaceBetween: 16,
			},
			768: {
				slidesPerView: 3,
				spaceBetween: 16,
			},
			1024: {
				slidesPerView: 3,
				spaceBetween: 32,
			},
			1200: {
				slidesPerView: 4,
				spaceBetween: 32,
			},
			1400: {
				slidesPerView: 4,
				spaceBetween: 40,
			},
		},
	});
});

$('.five-slider').each(function (index) {
	var loopDefault = {
		loop: true,
	};
	var autoplayDefault = {
		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
	};
	var centeredSlidesDefault = {};
	if ($(this).hasClass('no-loop')) {
		loopDefault = {
			loop: false,
		};
	}
	if ($(this).hasClass('no-autoplay')) {
		autoplayDefault = {
			autoplay: false,
		};
	}
	if ($(this).hasClass('centered-slides')) {
		centeredSlidesDefault = {
			centeredSlides: true,
			centeredSlidesBounds: true,
		};
	}
	$(this).find('.swiper').addClass(`swiper-five-${index}`);
	$(this).find('.swiper-pagination').addClass(`pagination-five-${index}`);
	$(this).find('.button-prev').addClass(`prev-five-${index}`);
	$(this).find('.button-next').addClass(`next-five-${index}`);
	const fiveSlider = new Swiper(`.swiper-five-${index}`, {
		...defaultSettingSwiper,
		...loopDefault,
		...autoplayDefault,
		speed: 1000,
		spaceBetween: 16,
		slidesPerView: 1,
		navigation: {
			prevEl: `.five-slider .prev-five-${index}`,
			nextEl: `.five-slider .next-five-${index}`,
		},
		pagination: {
			el: `.five-slider .pagination-five-${index}`,
			clickable: true,
			dynamicBullets: true,
		},
		breakpoints: {
			576: {
				slidesPerView: 2,
				spaceBetween: 16,
			},
			768: {
				slidesPerView: 3,
				spaceBetween: 16,
			},
			1200: {
				slidesPerView: 4,
				spaceBetween: 32,
			},
			1440: {
				slidesPerView: 5,
				spaceBetween: 32,
			},
		},
	});
});

$('.six-slider').each(function (index) {
	var loopDefault = {
		loop: true,
	};
	var autoplayDefault = {
		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
	};
	var centeredSlidesDefault = {};
	if ($(this).hasClass('no-loop')) {
		loopDefault = {
			loop: false,
		};
	}
	if ($(this).hasClass('no-autoplay')) {
		autoplayDefault = {
			autoplay: false,
		};
	}
	if ($(this).hasClass('centered-slides')) {
		centeredSlidesDefault = {
			centeredSlides: true,
			centeredSlidesBounds: true,
		};
	}
	$(this).find('.swiper').addClass(`swiper-six-${index}`);
	$(this).find('.swiper-pagination').addClass(`pagination-six-${index}`);
	$(this).find('.button-prev').addClass(`prev-six-${index}`);
	$(this).find('.button-next').addClass(`next-six-${index}`);
	const sixSlider = new Swiper(`.swiper-six-${index}`, {
		...defaultSettingSwiper,
		...loopDefault,
		...autoplayDefault,
		speed: 1000,
		spaceBetween: 16,
		slidesPerView: 2,
		navigation: {
			prevEl: `.six-slider .prev-six-${index}`,
			nextEl: `.six-slider .next-six-${index}`,
		},
		pagination: {
			el: `.six-slider .pagination-six-${index}`,
			clickable: true,
			dynamicBullets: true,
		},
		breakpoints: {
			320: {
				slidesPerView: 2,
				spaceBetween: 16,
			},
			576: {
				slidesPerView: 3,
				spaceBetween: 16,
			},
			768: {
				slidesPerView: 4,
				spaceBetween: 16,
			},
			1024: {
				slidesPerView: 5,
				spaceBetween: 16,
			},
			1200: {
				slidesPerView: 6,
				spaceBetween: 32,
			},
		},
	});
});

$('.auto-slider').each(function (index) {
	var loopDefault = {
		loop: true,
	};
	var autoplayDefault = {
		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
		speed: 1000,
	};
	var centeredSlidesDefault = {};
	var freeModeDefault = {}
	if ($(this).hasClass('no-loop')) {
		loopDefault = {
			loop: false,
		};
	}
	if ($(this).hasClass('no-autoplay')) {
		autoplayDefault = {
			autoplay: false,
			speed: 1000,
		};
	}
	if ($(this).hasClass('one-autoplay')) {
		autoplayDefault = {
			autoplay: {
				delay: 1,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},
			speed: 3000,
		};
	}
	if ($(this).hasClass('centered-slides')) {
		centeredSlidesDefault = {
			centeredSlides: true,
			centeredSlidesBounds: true,
		};
	}
	if ($(this).hasClass('centered-slides')) {
		centeredSlidesDefault = {
			centeredSlides: true,
			centeredSlidesBounds: true,
		};
	}
	if ($(this).hasClass('is-free-mode')) {
		freeModeDefault = {
			freeMode: true
		}
	}
	$(this).find('.swiper').addClass(`swiper-auto-${index}`);
	$(this).find('.swiper-pagination').addClass(`pagination-auto-${index}`);
	$(this).find('.button-prev').addClass(`prev-auto-${index}`);
	$(this).find('.button-next').addClass(`next-auto-${index}`);
	const autoSlider = new Swiper(`.swiper-auto-${index}`, {
		...defaultSettingSwiper,
		...loopDefault,
		...autoplayDefault,
		...freeModeDefault,
		spaceBetween: 16,
		slidesPerView: 'auto',
		navigation: {
			prevEl: `.auto-slider .prev-auto-${index}`,
			nextEl: `.auto-slider .next-auto-${index}`,
		},
		pagination: {
			el: `.auto-slider .pagination-auto-${index}`,
			clickable: true,
		},
		breakpoints: {
			1200: {
				spaceBetween: 32,
			},
		},
	});
});

var about2Thumbs = new Swiper(".about-2-section .about-2-thumbs", {
	spaceBetween: 16,
	slidesPerView: 4,
	watchSlidesProgress: true,
	breakpoints: {
		1200: {
			slidesPerView: 7,
		},
	}
});
var about2Top = new Swiper(".about-2-section .about-2-top", {
	...defaultSettingSwiper,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	spaceBetween: 16,
	rewind: true,
	navigation: {
		nextEl: ".about-2-section .button-next",
		prevEl: ".about-2-section .button-prev",
	},
	thumbs: {
		swiper: about2Thumbs,
	},
});

var factorySlider = new Swiper(".factory-slider .swiper", {
	...defaultSettingSwiper,
	loop: true,
	autoplay: {
		delay: 2000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	slidesPerView: "auto",
	centeredSlides: true,
	spaceBetween: 24,
	pagination: {
		el: ".factory-slider .swiper-pagination",
		clickable: true,
	},
	navigation: {
		prevEl: ".factory-slider .button-prev",
		nextEl: ".factory-slider .button-next",
	},
});

var customersSlider = new Swiper(".customers-slider .swiper", {
	autoplay: {
		delay: 3000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	rewind: true,
	slidesPerView: 2,
	grid: {
		rows: 2,
		fill: 'row',
	},
	spaceBetween: 30,
	pagination: {
		el: ".customers-slider .swiper-pagination",
		clickable: true,
	},
	navigation: {
		prevEl: ".customers-slider .button-prev",
		nextEl: ".customers-slider .button-next",
	},
	breakpoints: {
		576: {
			slidesPerView: 3,
			spaceBetween: 30,
		},
		768: {
			slidesPerView: 4,
			spaceBetween: 30,
		},
		1024: {
			slidesPerView: 5,
			spaceBetween: 30,
		},
		1200: {
			slidesPerView: 6,
			spaceBetween: 30,
		},
		1400: {
			slidesPerView: 6,
			spaceBetween: 40,
		},
	}
});

var productDetailThumbs = new Swiper(".product-thumbs-slider", {
	spaceBetween: 12,
	slidesPerView: 4,
	watchSlidesProgress: true,
	breakpoints: {
		1200: {
			slidesPerView: 6,
		},
	}
});
var productDetailTop = new Swiper(".product-top-slider", {
	...defaultSettingSwiper,
	spaceBetween: 16,
	navigation: {
		nextEl: ".product-detail-section .button-next",
		prevEl: ".product-detail-section .button-prev",
	},
	thumbs: {
		swiper: productDetailThumbs,
	},
});


function lozadInit() {
	// Initialize library to lazy load images
	const observer = lozad('.lozad', {
		threshold: 0.1,
		enableAutoReload: true,
	});
	// data-background-image="image.png"
	const backgroundObserver = lozad('.lozad-bg', {
		threshold: 0.1,
	});
	observer.observe();
	backgroundObserver.observe();
}

function fancyboxInit() {
	Fancybox.bind("[data-fancybox]", {
		showLoading: true,
		preload: true,
		infinite: false,
		// parentEl: document.forms[0], // Element containing main structure
		mainClass: "fancybox-wrapper", // Custom class name or multiple space-separated class names for the container
	});

	Fancybox.bind("a.popup-link", {
		showLoading: true,
		type: "iframe",
		preload: true,
	});

	Fancybox.bind('[data-fancybox="single"]', {
		groupAttr: false,
	});

	$(".btn-close-fancybox").on("click", function () {
		Fancybox.close();
	});
}

function countUpInit() {
	$('.count-up').each(function (index) {
		$(this).attr('id', `countUp-${index}`)
		const endVal = $(this).data('count');

		const options = {
			separator: ',',
			enableScrollSpy: true,
			scrollSpyDelay: 1000,
			scrollSpyOnce: true,
			useEasing: true,
			useGrouping: true,
		};

		const countUp = new CountUp(`countUp-${index}`, endVal, options);

		// if (!countUp.error) {
		// 	countUp.start();
		// } else {
		// 	console.error(countUp.error);
		// }

		var waypoint = new Waypoint({
			element: document.getElementsByClassName('section-count-up')[0],
			handler: function (direction) {

				// if (direction == "up") {
				// 	countUp.reset();
				// } else {
				countUp.start();
				// }

			},
			offset: '50%'
		});

		// countUp.handleScroll();
	});
}

function accordionCollapse() {
	$('.accordion-item .accordion-head').on('click', function () {
		$(this)
			.parent()
			.toggleClass('active')
			.siblings().removeClass('active')
		$(this)
			.next().slideToggle()
			.parent()
			.siblings().find('.accordion-content').slideUp()
	});
}

function loadMoreItem() {
	$('.load-more-wrap').each(function () {
		let $this = $(this);
		let loadMore = $this.data('load-more')
		let loadMoreItem = $this.find('.load-more-item');
		let loadMoreButton = $this.find('.load-more-button');
		$(loadMoreItem).slice(0, loadMore).show();

		$(loadMoreButton).on('click', function (e) {
			e.preventDefault();
			let loadMoreItemHidden = $this.find('.load-more-item:hidden');
			$(loadMoreItemHidden).slice(0, loadMore).slideDown();
			let loadMoreItemHiddenLength = $this.find('.load-more-item:hidden').length;
			if ($(loadMoreItemHiddenLength).length == 0) {
				$(loadMoreButton).fadeOut('slow');
			}
		});
	})
}
